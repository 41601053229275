import { Cookies } from "react-cookie";

const cookies = new Cookies();

type CookieSetOptions = {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const setCookie = (
  name: string,
  value: string,
  option?: CookieSetOptions
) => {
  return cookies.set(name, value, { ...option });
};

export const removeCookie = (name: string, option?: CookieSetOptions) => {
  return cookies.remove(name, { ...option });
};

export const getAllCookies = () => {
  return cookies.getAll();
};

export const removeAllCookies = () => {
  const cookieNames = Object.keys(getAllCookies());
  cookieNames.forEach((name) => {
    removeCookie(name, { path: "/" });
  });
};
